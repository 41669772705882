import styled from 'styled-components'

const UserNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const UserNavActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddUserButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const AddUserButtonLabel = styled.span`
  font-size: 16px;
  margin-right: 10px;
  color: #004a7a;
`

const UserContentContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-start;
  margin-top: 50px;
`

const PaginatorHolder = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`

export {
  AddUserButton,
  AddUserButtonLabel,
  PaginatorHolder,
  UserContentContainer,
  UserNavActionContainer,
  UserNavContainer
}
