import styled from 'styled-components'
import { colors } from '../../styles/variables'

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

const FlexDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled(FlexDiv)`
  background-color: #fff;
  width: 350px;
  min-height: 200px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  padding: 0 20px;
`

const ModalCardContent = styled(FlexDiv)`
  justify-content: flex-start;
  align-items: flex-start;
`

const ModalCardHeader = styled(FlexDiv)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
`

const ModalCardFooter = styled(FlexDiv)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ModalCardDescription = styled(FlexDiv)`
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: flex-start;
`

const Button = styled.button`
  display: flex;
  outline: none;
  outline: none;
  height: 35px;
  background-color: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 15px;
  margin: 5px 0;
  & span {
    font-size: 16px;
  }
`

const ModalConfirmButton = styled(Button)`
  background-color: ${colors.primary};
  border-radius: 5px;
  & span {
    color: #fff;
  }
`

const ModalDismissButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #0072bb;
  border-radius: 5px;
  & span {
    color: #0072bb;
  }
`

export {
  ModalCardContent,
  ModalCardDescription,
  ModalCardFooter,
  ModalCardHeader,
  ModalConfirmButton,
  ModalContainer,
  ModalDismissButton,
  ModalOverlay
}
