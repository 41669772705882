import styled from 'styled-components'

const CustomAreaChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 360px;
  height: 166px;
  border-radius: 8px;
  border: solid 2px #0072bb;
  padding: 17px 0 0;
`

const ChartCanvas = styled.canvas``

export { ChartCanvas, CustomAreaChartContainer }
