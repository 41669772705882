import { t } from 'i18n-js'
import { If } from 'react-extras'
import { Controller, ControllerRenderProps } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import { ErrorMessage, InputWrapper, Label } from './styles'

interface Props {
  invalidNumberError?: string
  errMessage: string | null
  name: string
  control: any
  label?: string
}

const PhoneNumberInput = ({
  errMessage,
  invalidNumberError,
  name,
  control,
  label
}: Props) => {
  const isError = !!errMessage || errMessage !== null

  return (
    <InputWrapper>
      <Label>
        {t(label ?? name)}{' '}
        <If condition={!!invalidNumberError}>
          <ErrorMessage>{invalidNumberError?.toLocaleLowerCase()}</ErrorMessage>
        </If>
        <If condition={isError}>
          <ErrorMessage>{errMessage}</ErrorMessage>
        </If>
        <Controller
          name={name}
          defaultValue={''}
          control={control}
          render={({ field }: { field: ControllerRenderProps }) => {
            return (
              <Input
                $isError={!!invalidNumberError || isError}
                placeholder={t(label ?? name)}
                international
                countryCallingCodeEditable
                defaultCountry="SE"
                {...field}
              />
            )
          }}
        />
      </Label>
    </InputWrapper>
  )
}

export default PhoneNumberInput

interface InputProps {
  $isError?: boolean
}

const Input = styled(PhoneInput)<InputProps>`
  position: relative;
  width: 100%;

  .PhoneInputCountry {
    top: 1.2rem;
    left: 0.8rem;
    position: absolute;
    align-self: stretch;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow:
      0 0 0 1px rgba(0, 0, 0, 0.5),
      inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    height: 19px;
  }

  .PhoneInputInput {
    width: calc(100% - 3.8rem);
    padding: 0.8rem 0.8rem 0.8rem 3rem;
    border: ${({ $isError }) =>
      $isError ? '1px solid red' : '1px solid rgb(43, 43, 43)'};
    border-radius: 4px;
    margin-top: 0.5rem;
  }
`
