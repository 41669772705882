import { Pagination } from '@mui/material'
import { IconFileFind } from 'assets/images'
import { Loading, Table } from 'components'
import { createPagination, getInitials } from 'helpers'
import { t } from 'i18n'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { getAllReports } from 'services'
import { IReportListData, ReportStatus, UserInfo } from 'store/ducks'
import * as AuthActions from 'store/ducks/auth/actions'
import styled from 'styled-components'
import { ReportList } from '..'
import { ApplicationState } from '../../../../store'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const StyledPagination = styled(Pagination)`
  margin: 2rem auto;
`

const StyledTable = styled(Table)`
  td,
  th {
    &:last-child {
      text-align: right;
    }
  }
`

const IconButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: #0072bb;
  outline: none;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  &:nth-child(2) {
    margin-left: 10px;
  }

  svg {
    fill: white;
  }
`
interface ReportListTableProps {
  user: UserInfo
  reportStatus: ReportStatus
  searchKey: string
  activeReportList: ReportList
}

const ConnectedLink = ({ relatedReports = [] }: IReportListData) => {
  const isEmpty = relatedReports && relatedReports.length <= 0
  const relatedReport = relatedReports.filter(item => item.type === 'RELATED')
  if (isEmpty || !relatedReport.length) return null

  return (
    <>
      {relatedReport.map((item, index) => {
        const isLast = relatedReport.length === index + 1

        return (
          <div key={index}>
            <Link to={`/report-overview/${item.reportId}`}>
              {item.SK}
              {isLast ? '' : ','}
            </Link>{' '}
          </div>
        )
      })}
    </>
  )
}

const ReportListTable = ({
  user,
  reportStatus,
  searchKey,
  activeReportList
}: ReportListTableProps) => {
  const [userReports, setUserReports] = useState<
    IReportListData[] | undefined | []
  >([])

  const [filteredReports, setFilteredReports] = useState<
    IReportListData[] | undefined | []
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [numOfPages, setNumOfPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const maxItemPerPage = 10

  const getResorts = useCallback(
    async (reportStatus: ReportStatus) => {
      setIsLoading(true)
      try {
        const reports =
          reportStatus !== ReportStatus.ALL
            ? await getAllReports({ reportStatus })
            : await getAllReports()

        if (activeReportList === ReportList.USER) {
          const newUserReports = reports?.filter(report => {
            return report.mainCreator.id === user.id
          })
          return setUserReports(newUserReports)
        }

        return setUserReports(reports)
      } catch (error) {
        console.error(error)
      }
    },
    [user.id, activeReportList]
  )

  useEffect(() => {
    getResorts(reportStatus)
  }, [getResorts, reportStatus, searchKey, user.resortId])

  useEffect(() => {
    setNumOfPages(1)

    const sortedReports = userReports?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    if (searchKey.length > 0) {
      const newFilteredReports = sortedReports?.filter(report => {
        const isInjuryNumber = report.SK.toLowerCase().includes(searchKey)
        const isPatient = report.name.toLowerCase().includes(searchKey)
        return isPatient || isInjuryNumber
      })

      const { items, pages } = createPagination({
        items: newFilteredReports as [],
        maxItem: maxItemPerPage
      })

      setFilteredReports(items)
      setNumOfPages(pages)
      return setIsLoading(false)
    }

    const { items, pages } = createPagination({
      items: sortedReports as [],
      maxItem: maxItemPerPage
    })

    setFilteredReports(items)
    setNumOfPages(pages)
    return setIsLoading(false)
  }, [searchKey, reportStatus, userReports])

  const columns = [
    {
      path: 'SK',
      label: t('injuryReportNumber')
    },
    {
      content: ({ reportDate }: any) => (
        <>{moment(reportDate).format('DD.MM.YYYY')}</>
      ),
      label: t('date')
    },
    {
      path: 'name',
      label: t('patientName')
    },
    {
      content: (props: IReportListData) => <ConnectedLink {...props} />,
      label: t('connectedTo')
    },

    {
      path: 'reportStatus',
      label: t('reportStatus')
    },
    {
      content: ({ mainCreator }: any) => (
        <>{getInitials(mainCreator?.fullName)}</>
      ),
      label: t('treatedBy')
    },
    {
      path: 'version',
      label: t('version')
    },
    {
      content: ({ createdAt }: any) => (
        <>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</>
      ),
      label: t('creationDate')
    },
    {
      content: ({ reportId }: any) => (
        <Link to={`/report-overview/${reportId}`}>
          <IconButton>
            <IconFileFind title={t('viewReport')} />
          </IconButton>
        </Link>
      )
    }
  ]

  const onPaginationChange = (_: unknown, page: number) => {
    setCurrentPage(page)
  }

  const dataToRender =
    numOfPages > 1 ? filteredReports?.[currentPage - 1] : filteredReports

  const isReadyToRender =
    !isLoading && !!filteredReports && filteredReports.length > 0

  if (!dataToRender || !(dataToRender as IReportListData[]).length) return null
  return (
    <Container>
      <If condition={isLoading}>
        <Loading />
      </If>
      <If condition={isReadyToRender}>
        <StyledTable columns={columns} data={dataToRender} />
        <If condition={numOfPages > 1}>
          <StyledPagination count={numOfPages} onChange={onPaginationChange} />
        </If>
      </If>
    </Container>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.data
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportListTable)
