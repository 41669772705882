import styled from 'styled-components'

const UserEditFormContainer = styled.form`
  width: 350px;
`

const UserEditAccessDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 10px 0;
`

const Separator = styled.div`
  margin: 0 10px;
  border: 1px solid #0072bb;
  width: 16px;
  margin-top: 20px;
`

const UserProfileOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const UserEditLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #0072bb;
  margin-top: 20px;
`

const ResetPasswordContainer = styled.div`
  margin: 15px 0;
`

export {
  ResetPasswordContainer,
  Separator,
  UserEditAccessDate,
  UserEditFormContainer,
  UserEditLabel,
  UserProfileOptions
}
