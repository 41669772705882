import { t } from 'i18n'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import theme from 'styles/theme'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  font:
    400 16px Roboto,
    sans-serif;
  color: #252525;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Label = styled.label`
  margin-bottom: 0.875rem;
  font-weight: 300;
  font-size: 0.875rem;
  color: ${theme.colors.black};
`

const Input = styled(DatePicker)`
  width: calc(100% - 2rem);
  padding: 1rem;
  border-radius: 5px;
  font-weight: 300;
  border: 1px solid #0072bb;
  color: #919191;
  outline: none;
  cursor: pointer;
  position: relative;
  .date-picker {
    inset: 39px auto auto 0px !important;
  }
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`

interface DateRangePickerProps {
  initialStartDate: string
  initialEndDate: string
  onChange: (key: string, date: Date | null | undefined) => void
}

const DateRangePicker = ({
  initialStartDate,
  initialEndDate,
  onChange
}: DateRangePickerProps) => {
  const today: Date | null | undefined = new Date()
  return (
    <Container>
      <InputContainer>
        <Label htmlFor="startDate">{t('startDate')}:</Label>
        <Input
          dateFormat={'dd/MM/yyyy'}
          selected={moment(initialStartDate).toDate()}
          maxDate={today}
          onChange={date => onChange('startDate', date as Date)}
        />
      </InputContainer>
      <InputContainer>
        <Label htmlFor="endDate">{t('endDate')}:</Label>
        <Input
          dateFormat={'dd/MM/yyyy'}
          selected={moment(initialEndDate).toDate()}
          minDate={moment(initialStartDate).toDate()}
          maxDate={today}
          onChange={date => onChange('endDate', date as Date)}
          popperClassName="date-picker"
        />
      </InputContainer>
    </Container>
  )
}

export default DateRangePicker
