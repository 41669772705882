import { If } from 'react-extras'
import styled from 'styled-components'
import theme from 'styles/theme'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
`

const Checkmark = styled.span<{ checked?: boolean }>`
  position: absolute;
  top: 4px;
  left: 4px;
  height: 8px;
  width: 8px;
  background-color: #155194;
  display: ${props => (props.checked ? 'block' : 'none')};
`

const RadioButtonContainer = styled.div`
  display: flex;
  border: 1px solid #155194;
  height: 16px;
  width: 16px;
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  &:hover input ~ .checkmark {
    background-color: #155194;
    display: block;
  }
`
const Label = styled.label`
  font-size: 16px;
  color: ${theme.colors.black};
  margin-left: 10px;
`

interface IRadioButtonProps {
  checked?: boolean
  label?: string
  handleCheckedChange: () => void
}

const RadioButton = ({
  checked,
  label,
  handleCheckedChange
}: IRadioButtonProps) => {
  return (
    <Container onClick={handleCheckedChange}>
      <RadioButtonContainer>
        <Checkmark checked={checked} className="checkmark" />
      </RadioButtonContainer>
      <If condition={label ? true : false}>
        <Label>{label}</Label>
      </If>
    </Container>
  )
}

export { RadioButton }
