import { t } from 'i18n'
import { ReactNode } from 'react'
import {
  ReportOverviewCardContainer,
  ReportOverviewCardContentContainer,
  ReportOverviewCardHeaderContainer,
  ReportOverviewLabel
} from './styled'

interface Props {
  title: string
  children?: ReactNode
}

const ReportOverviewCard = ({ title, children }: Props) => {
  return (
    <ReportOverviewCardContainer>
      <ReportOverviewCardHeaderContainer>
        <div>
          <ReportOverviewLabel
            padding={'0 0 15px 0'}
            color="#004A7A"
            size={25}
            weight={500}
          >
            {t(title)}
          </ReportOverviewLabel>
        </div>
      </ReportOverviewCardHeaderContainer>
      <ReportOverviewCardContentContainer>
        {children}
      </ReportOverviewCardContentContainer>
    </ReportOverviewCardContainer>
  )
}

export default ReportOverviewCard
