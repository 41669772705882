import { IconArrowRight, IconNotepad } from 'assets/images'
import moment from 'moment'
import { Monitoring } from 'store/ducks'
import {
  EventMonitoringContainer,
  EventMonitoringIcon,
  EventMonitoringText
} from './styled'

interface Props {
  openMonitoring: (monitoring: Monitoring) => void
  monitoring: Monitoring
}

const EventMonitoring = ({ openMonitoring, monitoring }: Props) => {
  return (
    <EventMonitoringContainer onClick={() => openMonitoring(monitoring)}>
      <EventMonitoringIcon>
        <IconNotepad width={24} height={24} fill={'#e33131'} />
      </EventMonitoringIcon>
      <EventMonitoringText>
        {moment(monitoring.monitoringHour).format('HH:mm')}
      </EventMonitoringText>
      <EventMonitoringIcon>
        <IconArrowRight width={16} height={16} fill={'#0072BB'} />
      </EventMonitoringIcon>
    </EventMonitoringContainer>
  )
}

export { EventMonitoring }
