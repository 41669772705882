import styled from 'styled-components'

const TimeAndDateContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  height: 100%;
  justify-content: ${props => (props.vertical ? ' flex-end' : 'space-between')};
  ${props =>
    !props.vertical &&
    `
    min-width: 250px;
  `}
  padding-right: 5px;
  & span {
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    color: #004a7a;
    margin-bottom: 20px;
  }
  & label {
    color: #004a7a;
    text-align: right;
    margin-bottom: 5px;
  }
`

const TimeAndDateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`

export { TimeAndDateContainer, TimeAndDateContentWrapper }
