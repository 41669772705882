import { useState } from 'react'
import { If } from 'react-extras'
import styled from 'styled-components'
import Doughnut from './Doughnut'
import PieChart from './PieChart'

const Container = styled.div`
  margin-bottom: 5rem;
  .chart-container {
    border-top-left-radius: 0;
  }
`

const TabContainer = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  margin-bottom: -2px;
`

const Tab = styled.li`
  padding: 1rem;
  cursor: pointer;
  color: white;
  border: 2px solid #0072bb;
  background-color: #0072bb;
  border-bottom: 0;
  &.active {
    color: #0072bb;
    background-color: white;
  }
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`

interface TabsProps {
  labels: string[]
  data: number[]
  title: string
}

const Tabs = ({ data, ...rest }: TabsProps) => {
  const [activeTab, setActiveTab] = useState('number')

  const handleOnClick = (tab: string) => {
    setActiveTab(tab)
  }

  if (!data) return null
  return (
    <Container>
      <TabContainer>
        <Tab
          className={activeTab === 'number' ? `active` : ''}
          onClick={() => handleOnClick('number')}
        >
          Numbers
        </Tab>
        <Tab
          className={activeTab === 'percent' ? `active` : ''}
          onClick={() => handleOnClick('percent')}
        >
          Percent
        </Tab>
      </TabContainer>
      <If condition={activeTab === 'number'}>
        <Doughnut data={data} {...rest} />
      </If>
      <If condition={activeTab === 'percent'}>
        <PieChart data={data} {...rest} />
      </If>
    </Container>
  )
}

export default Tabs
