import styled from 'styled-components'

const LabelCircleContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: ${props => props.backgroundColor};
`

const LabelCircleText = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-size: 13px;
`

interface ILabelCircleProps {
  value: string
  backgroundColor?: string
  color?: string
  click?: () => void
}

const LabelCircle = ({
  value,
  backgroundColor = '#F7CC00',
  color = '#fff',
  click
}: ILabelCircleProps) => {
  const handleClick = () => {
    if (click) click()
  }
  return (
    <LabelCircleContainer
      backgroundColor={backgroundColor}
      onClick={handleClick}
    >
      <LabelCircleText color={color}>{value}</LabelCircleText>
    </LabelCircleContainer>
  )
}

export { LabelCircle }
