import { IconCheck, IconCircleX } from 'assets/images'
import { Loading } from 'components'
import { t } from 'i18n'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { If } from 'react-extras'
import { updateUserInfo } from 'services'
import styled from 'styled-components'
import { BUTTON_COLOR, Button, ButtonContainer } from '../styled'
import MyPageInput from './Input'

const FakeInput = styled.div`
  margin: 1.5rem 0;
`

const FakeInputTitle = styled.h4`
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`

const FakeInputValue = styled.p`
  font-size: 0.875rem;
  padding: 1rem;
  margin-top: 0.5rem;
  border: 1px solid #0072bb;
  border-radius: 5px;
  background-color: #80808030;
  cursor: not-allowed;
`

interface MyPageFormProps {
  user: UserProps
  isUserEdited: boolean
  setIsUserEdited: React.Dispatch<React.SetStateAction<boolean>>
}

interface UserProps {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phoneNumber: string | undefined
}

const MyPageForm = ({
  user,
  setIsUserEdited,
  isUserEdited
}: MyPageFormProps) => {
  const [userDetail, setUserDetail] = useState<{
    firstName: string
    lastName: string
  }>({
    firstName: '',
    lastName: ''
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setUserDetail({
      firstName: user.firstName || '',
      lastName: user.lastName || ''
    })
  }, [user.firstName, user.lastName])

  useEffect(() => {
    const isEdited = !isEqual(
      {
        firstName: user.firstName,
        lastName: user.lastName
      },
      userDetail
    )
    setIsUserEdited(isEdited)
  }, [userDetail, user.firstName, user.lastName, setIsUserEdited])

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    if (userDetail.firstName.length < 2 || userDetail.lastName.length < 2)
      return setIsLoading(false)

    try {
      await updateUserInfo(userDetail)
      window.location.reload()
    } catch (error) {
      console.error(error)
      return setIsLoading(false)
    }
  }

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget

    setUserDetail({
      ...userDetail,
      [name]: value
    })
  }

  const handleOnReset = () =>
    setUserDetail({
      firstName: user.firstName || '',
      lastName: user.lastName || ''
    })

  return (
    <form onSubmit={handleOnSubmit}>
      <If condition={isLoading}>
        <Loading />
      </If>
      <If condition={!isLoading}>
        <MyPageInput
          label={t('firstName')}
          name="firstName"
          onChange={e => handleOnChange(e)}
          value={userDetail.firstName}
        />
        <MyPageInput
          label={t('lastName')}
          name="lastName"
          onChange={e => handleOnChange(e)}
          value={userDetail.lastName}
        />
        <FakeInput>
          <FakeInputTitle>{t('email')}</FakeInputTitle>
          <FakeInputValue>{user.email}</FakeInputValue>
        </FakeInput>
        <FakeInput>
          <FakeInputTitle>{t('phoneNumber')}</FakeInputTitle>
          <FakeInputValue>{user.phoneNumber}</FakeInputValue>
        </FakeInput>
        <If condition={isUserEdited}>
          <ButtonContainer>
            <Button type="submit" $bgColor={BUTTON_COLOR.BLUE}>
              {t('save')} <IconCheck />
            </Button>
            <Button
              onClick={handleOnReset}
              $bgColor={BUTTON_COLOR.RED}
              $bordered
            >
              {t('cancel')} <IconCircleX />
            </Button>
          </ButtonContainer>
        </If>
      </If>
    </form>
  )
}

export default MyPageForm
