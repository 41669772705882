import styled from 'styled-components'

const NewUserContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const NewUserContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const NewUserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
`
const NewUserSaveContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`

const NewUserLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #0072bb;
  margin-top: 20px;
  margin-bottom: 20px;
`

const NewUserAccessDisclaimer = styled.div`
  color: #919191;
  font-size: 18px;
  max-width: 300px;
  padding: 50px 0 30px 0;
`

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`

export {
  NewUserAccessDisclaimer,
  NewUserContainer,
  NewUserContentContainer,
  NewUserFormContainer,
  NewUserLabel,
  NewUserSaveContainer,
  PageHeaderWrapper
}
