import DatePicker from 'react-datepicker'
import styled from 'styled-components'

const DateTimePickerContainer = styled(DatePicker)`
  height: 30px;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  border: 1px solid #0072bb;
  font-size: 16px;
  text-align: center;
`

export { DateTimePickerContainer }
