import { Reducer } from 'redux'
import { AuthState, AuthTypes, UserInfo } from './types'

const INITIAL_STATE: AuthState = {
  data: {} as UserInfo,
  loading: false,
  error: false,
  errorStack: undefined,
  userResortListData: []
}

const reducer: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOGIN_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case AuthTypes.USER_INFO:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.USER_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.UPDATE_USER_INFO:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.LOGOFF_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.LOGOFF_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.USER_INFO_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case AuthTypes.UPDATE_RESORT_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.UPDATE_RESORT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          resort: {
            ...action.payload
          }
        },
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.UPDATE_RESORT_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case AuthTypes.CHANGE_RESORT_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.CHANGE_RESORT_SUCCESS:
      return {
        ...state,
        data: {
          isLogged: true,
          ...action.payload
        },
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.CHANGE_RESORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case AuthTypes.CHANGE_USER_RESORT_REQUEST:
      return { ...state, loading: true, error: false, errorStack: undefined }
    case AuthTypes.CHANGE_USER_RESORT_SUCCESS:
      return {
        ...state,
        data: {
          isLogged: true,
          ...action.payload
        },
        loading: false,
        error: false,
        errorStack: undefined
      }
    case AuthTypes.CHANGE_USER_RESORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorStack: action.payload.errorStack
      }
    case AuthTypes.USER_RESORT_LIST:
      return {
        ...state,
        userResortListData: action.payload,
        loading: false,
        error: false,
        errorStack: undefined
      }
    default:
      return state
  }
}

export default reducer
