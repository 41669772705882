import styled from 'styled-components'

export const AudioItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #e5e5e5;
  margin: 5px 0;
  align-items: center;
  height: 60px;
  cursor: pointer;
`

export const AudioRecordDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #e33131;
`

export const AudioItemText = styled.div`
  font-size: 16px;
  color: #0072bb;
`
