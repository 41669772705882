import { IconLogo } from 'assets/images'
import { t } from 'i18n'
import { theme } from 'styles/theme'
import SignInForm from './SignInForm'
import {
  CenterContainer,
  SignInContainer,
  SignInImage,
  SignInImageText
} from './styled'

const SignInPage = () => {
  return (
    <CenterContainer>
      <SignInContainer>
        <SignInImage>
          <CenterContainer>
            <IconLogo />
            <SignInImageText>{t`appIntroTitle`}</SignInImageText>
            <SignInImageText color={theme.colors.yellow}>
              {t`appIntroSubtitle`}
            </SignInImageText>
          </CenterContainer>
        </SignInImage>
        <SignInForm />
      </SignInContainer>
    </CenterContainer>
  )
}

export default SignInPage
