import styled from 'styled-components'
import theme from 'styles/theme'

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px ${theme.colors.primary};
`

export const SearchInputText = styled.input`
  font-size: 16px;
  color: #0072bb;
  border: none;
`
