import styled from 'styled-components'
import { theme } from 'styles/theme'

const FormSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.black};
  color: ${theme.colors.black};
  position: relative;
  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    fill: ${theme.colors.red};
    vertical-align: text-bottom;
    margin-right: 10px;
  }
`

const FormSectionTitle = styled.h3`
  font-size: ${theme.textSizes.large};
  font-weight: 500;
  color: ${theme.colors.black};
`

const MemoModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  padding: 20px;
  width: 360px;
`

const ModalCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
  svg {
    fill: ${theme.colors.lightGray} !important;
  }
`

const FormSectionButtons = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
`

const MemoList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`

const MemoListItem = styled.li`
  padding: 10px;
  margin-top: 5px;
  font-size: ${theme.textSizes.smaller};
  line-height: 1.6;
  color: ${theme.colors.black};
  box-shadow: -1px 1px 3px 0px rgb(0 0 0 / 15%);
  h4 {
    margin: 0;
    padding: 0;
    font-size: ${theme.textSizes.small};
    color: ${theme.colors.black};
  }
`

const ImageModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  padding: 20px;
  width: 380px;
  min-height: 450px;
`

const ImageGalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const RecordModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  padding: 15px;
  width: 360px;
`

export {
  FormSectionButtons,
  FormSectionHeader,
  FormSectionTitle,
  ImageGalleryContainer,
  ImageModal,
  MemoList,
  MemoListItem,
  MemoModal,
  ModalCloseIcon,
  RecordModal
}
