import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
`

const Title = styled.h3`
  margin-top: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  text-rendering: geometricPrecision;
`

interface ChartContainerProps {
  children: React.ReactNode
  title: string
}

const ChartContainer = ({ children, title }: ChartContainerProps) => {
  return (
    <Container className="chart-container">
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

export default ChartContainer
