import { t } from 'i18n'
import { memo } from 'react'
import { Pie } from 'react-chartjs-2'
import styled from 'styled-components'
import ChartContainer from './ChartContainer'

const StyledPieChart = styled(Pie)`
  margin-top: 2rem;
  width: 100% !important;
  max-height: 400px;
`

interface PieChartProps {
  labels: string[]
  data: number[]
  title: string
}

const PieChart = ({ labels, data, title }: PieChartProps) => {
  if (!data) return null

  const colors = ['#F7CC00', '#0072BB', '#389FBF', '#9CBF38', '#38BF86']
  const options = {
    responsive: true,
    scales: {
      y: {
        display: false
      },
      x: {
        display: false
      }
    },

    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0
          const dataArr = ctx.chart.data.datasets[0].data
          dataArr.forEach((data: any) => {
            sum += data
          })
          const percentage = ((value * 100) / sum).toFixed(0) + '%'
          return percentage
        },
        backgroundColor: function (context: any) {
          return context.dataset.backgroundColor
        },
        color: 'white',
        padding: 0,
        display: 'auto'
      },

      labels: {
        render: 'percentage',
        colors: 'black'
      },

      legend: {
        position: 'right' as const,
        labels: {
          padding: 16,
          font: {
            size: 16
          },
          pointStyle: 'circle',
          usePointStyle: true
        }
      },
      title: {
        display: false
      }
    }
  }

  const defaultData = {
    labels: labels.map(label => t(label)),
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1
      }
    ]
  }

  return (
    <ChartContainer title={title}>
      <StyledPieChart data={defaultData} options={options} />
    </ChartContainer>
  )
}

export default memo(PieChart)
