import ForgotPasswordImg from 'assets/images/sloa-guy-skiing.png'
import ReactCodeInput from 'react-code-input'

import styled from 'styled-components'
import { theme } from 'styles/theme'

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${theme.colors.primary} 0px 10px 50px -20px;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
`

const ForgotPasswordImage = styled.div`
  position: relative;
  background-image: url(${ForgotPasswordImg});
  background-size: cover;
  width: 100%;
  height: 240px;
  border-radius: 5px;
`

const ForgotPasswordImageText = styled.div<{ color?: string }>`
  font-size: ${theme.textSizes.normal};
  color: ${({ color }) => (color ? color : theme.colors.white)};
`

const StepHeading = styled.h2`
  margin: 30px auto;
  font-size: ${theme.textSizes.large};
  align-self: flex-start;
  color: ${theme.colors.black};
`

const ForgotPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding: 20px;
`

const ErrorText = styled.span`
  display: block;
  color: ${theme.colors.red};
  text-align: center;
  margin: 15px;
`

const FullWidth = styled.div`
  width: 100%;
  margin-top: 30px;
`

const SignInLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  margin-bottom: 20px;
`

const LinkSignPassword = styled.div`
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  margin-top: 13px;
  cursor: pointer;
`

const PasswordChangedSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PasswordChangedSuccessText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  flex: 1;
  padding-top: 30px;
  align-items: flex-start;
`

const CodeInput = styled(ReactCodeInput)`
  display: flex !important;
  justify-content: space-around;
  margin-bottom: 2rem;
  gap: 1rem;

  input {
    font-size: 2rem;
    text-align: center;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 100%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin: 0;
    font-size: 4rem;
  }
`

const Message = styled.p`
  margin-top: 2rem;
`

export {
  CenterContainer,
  CodeInput,
  ErrorText,
  ForgotPasswordContainer,
  ForgotPasswordFormContainer,
  ForgotPasswordImage,
  ForgotPasswordImageText,
  FullWidth,
  LinkSignPassword,
  Message,
  PasswordChangedSuccessContainer,
  PasswordChangedSuccessText,
  SignInLinkContainer,
  StepHeading
}
