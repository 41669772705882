import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 1.5rem 0;
`
const StyledLabel = styled.label`
  display: grid;
  font-size: 0.8rem;
  font-weight: 300;
`
const StyledInput = styled.input`
  font-size: 0.875rem;
  font-weight: 300;
  padding: 1rem;
  margin-top: 0.5rem;
  border: 1px solid #0072bb;
  border-radius: 5px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

interface MyPageInputProps {
  label: string
  name: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  value: string
  type?: string
}

const MyPageInput = ({
  label,
  name,
  type = 'text',
  ...rest
}: MyPageInputProps) => {
  return (
    <Container>
      <StyledLabel>
        {label}
        <StyledInput type={type} name={name} id="name" {...rest} />
      </StyledLabel>
    </Container>
  )
}

export default MyPageInput
