import styled from 'styled-components'
import DropdownFilter from '../../../containers/Statistics/Filters/DropdownFilter'

export const Button = styled.button`
  margin: auto 0;
  padding: 1rem;
  border: none;
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: 0.5s;

  svg {
    margin-left: 1rem;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: white;
    transition: 0.5s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: transparent;

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`
export const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: end;
`

export const NavButtonContainer = styled.div`
  width: 800px;
`

export const NavButton = styled.button<{ $isActive: boolean }>`
  padding: 0 0 0.5rem;
  margin-right: 2rem;
  border: 0;
  background-color: transparent;
  font-size: 1.25rem;
  cursor: pointer;

  border-bottom: ${({ $isActive, theme }) =>
    $isActive && `2px solid ${theme.colors.primary}`};

  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.primary : theme.colors.black};
`

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const FilterButton = styled.button<{ $isActive: boolean }>`
  padding: 0 0 0.5rem;
  margin-left: 2rem;
  border: 0;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;

  border-bottom: ${({ $isActive, theme }) =>
    $isActive && `2px solid ${theme.colors.primary}`};

  color: ${({ $isActive, theme }) => $isActive && theme.colors.primary};
`

export const StyledDropDown = styled(DropdownFilter)`
  width: 200px;
  margin-left: auto;
`

export const SearchInput = styled.input`
  padding: 0.875rem 0.5rem;
  width: 500px;
  border: 1px solid #0072bb;
  border-radius: 5px;
`
