import { action } from 'typesafe-actions'
import { Resort, Seasons } from '../report/types'
import { AuthTypes, UserInfo } from './types'

export const loginRequest = (phoneNumber: string, password: string) =>
  action(AuthTypes.LOGIN_REQUEST, { phoneNumber, password })

export const loginSuccess = (data: UserInfo) =>
  action(AuthTypes.LOGIN_SUCCESS, data)

export const loginFailure = (error: Error) =>
  action(AuthTypes.LOGIN_FAILURE, { errorStack: error })

export const userInfoRequest = () => action(AuthTypes.USER_INFO)

export const userInfoSuccess = (data: UserInfo) =>
  action(AuthTypes.USER_INFO_SUCCESS, data)

export const setUserResortList = (data: Resort[]) =>
  action(AuthTypes.USER_RESORT_LIST, data)

export const userInfoFailure = (error: Error) =>
  action(AuthTypes.USER_INFO_FAILURE, { errorStack: error })

export const updateUserInfoRequest = (data: UserInfo) =>
  action(AuthTypes.UPDATE_USER_INFO, data)

export const logoffFailure = (error: Error) =>
  action(AuthTypes.LOGOFF_FAILURE, { errorStack: error })

export const logoffRequest = () => action(AuthTypes.LOGOFF_REQUEST)

export const logoffSuccess = (data: UserInfo) =>
  action(AuthTypes.LOGOFF_SUCCESS, data)

export const updateResortRequest = (resortId: string, seasonName: Seasons) =>
  action(AuthTypes.UPDATE_RESORT_REQUEST, { resortId, seasonName })

export const updateResortSuccess = (data: Resort) =>
  action(AuthTypes.UPDATE_RESORT_SUCCESS, data)

export const updateResortFailure = (error: Error) =>
  action(AuthTypes.UPDATE_RESORT_FAILURE, { errorStack: error })

export const changeResortRequest = (resortId: string) =>
  action(AuthTypes.CHANGE_RESORT_REQUEST, { resortId })

export const changeResortSuccess = (data: UserInfo) =>
  action(AuthTypes.CHANGE_RESORT_SUCCESS, data)

export const changeResortFailure = (error: Error) =>
  action(AuthTypes.CHANGE_RESORT_FAILURE, { errorStack: error })

export const changeUserResortRequest = (resortId: string) =>
  action(AuthTypes.CHANGE_USER_RESORT_REQUEST, { resortId })

export const changeUserResortSuccess = (data: UserInfo) =>
  action(AuthTypes.CHANGE_USER_RESORT_SUCCESS, data)

export const changeUserResortFailure = (error: Error) =>
  action(AuthTypes.CHANGE_USER_RESORT_FAILURE, { errorStack: error })
