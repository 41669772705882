import FormTitle from '../../FormTitle'
import { FlexRow } from '../../styled'
import AutomaticInfoLocation from './AutomaticInfoLocation'
import AutomaticInfoWeather from './AutomaticInfoWeather'

const AutomaticInfoSection = () => {
  return (
    <>
      <FormTitle title="weatherAndLocation" />
      <FlexRow>
        <AutomaticInfoWeather />
        <AutomaticInfoLocation />
      </FlexRow>
    </>
  )
}

export default AutomaticInfoSection
