import { IconDownload, IconPlusCircle } from 'assets/images'
import { Container, Loading, Spinner, Tab, Tabs, TimeAndDate } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import moment from 'moment'
import { PageHeaderWrapper } from 'pages/NewUser/styled'
import { useCallback, useEffect, useState } from 'react'
import { CSVDownload } from 'react-csv'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserToExport, getUsers } from 'services'
import { ApplicationState } from 'store'
import { Role } from 'store/ducks'
import AllUsers from './AllUsers'
import Archived from './Archived'
import Pending from './Pending'
import {
  AddUserButton,
  AddUserButtonLabel,
  UserContentContainer,
  UserNavActionContainer,
  UserNavContainer
} from './styled'

enum TabEnum {
  ALL = 'Alle brukere',
  PENDING = 'Brukere som venter på godkjenning',
  ARCHIVED = 'Arkivert',
  DOWNLOAD = 'DOWNLOAD'
}

const UsersPage = ({ resortId }: { resortId: string | undefined }) => {
  const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.ALL)
  const [loading, setLoading] = useState<boolean>(false)
  const [AllUsersItems, setAllUsersItems] = useState<TableItem[]>([])
  const [pendingUsersItems, setPendingUsersItems] = useState<TableItem[]>([])
  const [archivedUsersItems, setArchivedUsersItems] = useState<TableItem[]>([])
  const [csvData, setCsvData] = useState<any[]>([])

  const navigate = useNavigate()

  const handleActiveTab = (name: string) => {
    setActiveTab(name as TabEnum)
  }

  const addNewUser = () => {
    navigate('/new-user')
  }
  const adminGetUsers = useCallback(async () => {
    setLoading(true)
    const _users = await getUsers()
    const _allUsers = _users
      .filter(user => user.userStatus === 'ACTIVE')
      .map(user => ({
        id: user.id,
        readonly:
          user.access.filter(access => access.role === Role.SYS_ADMIN).length >
          0,
        values: [
          user.firstName,
          user.phoneNumber,
          `${
            user.access
              ? moment(user.access[0].startsOn).format('DD.MM.YYYY') +
                ' - ' +
                moment(user.access[0].endsOn).format('DD.MM.YYYY')
              : ''
          }`,
          `${user.access ? t(user.access[0].role) : '-'}`,
          'edit'
        ]
      }))

    const _pendingUsers = _users
      .filter(user => user.userStatus === 'PENDING')
      .map(user => ({
        id: user.id,
        readonly:
          user.access.filter(access => access.role === Role.SYS_ADMIN).length >
          0,
        values: [
          user.firstName,
          user.phoneNumber,
          user.email,
          moment(user.createdAt).format('DD.MM.YYYY'),
          'approve'
        ]
      }))

    const _archivedUsers = _users
      .filter(user => user.userStatus === 'ARCHIVED')
      .map(user => ({
        id: user.id,
        readonly:
          user.access.filter(access => access.role === Role.SYS_ADMIN).length >
          0,
        values: [
          user.firstName,
          user.phoneNumber,
          user.email,
          moment(user.createdAt).format('DD.MM.YYYY'),
          'openUser'
        ]
      }))

    setAllUsersItems(_allUsers)
    setArchivedUsersItems(_archivedUsers)
    setPendingUsersItems(_pendingUsers)

    setLoading(false)
  }, [])

  useEffect(() => {
    adminGetUsers()
  }, [adminGetUsers, resortId])

  const [downloading, setDownloading] = useState<boolean>(false)

  const handleDownload = async () => {
    setDownloading(true)
    const data = await getUserToExport()
    setCsvData(data)
    setDownloading(false)
  }

  return (
    <Container>
      <PageHeaderWrapper>
        <PageTitle>{t('usersOverview')}</PageTitle>
        <TimeAndDate vertical />
      </PageHeaderWrapper>
      <UserNavContainer>
        <Tabs>
          <Tab
            title={t('allUsers')}
            name={TabEnum.ALL}
            handleActive={handleActiveTab}
            active={activeTab === TabEnum.ALL}
          />
          <Tab
            title={t('usersAwaitingApproval')}
            name={TabEnum.PENDING}
            count={pendingUsersItems.length}
            handleActive={handleActiveTab}
            active={activeTab === TabEnum.PENDING}
          />
          <Tab
            title={t('archived')}
            name={TabEnum.ARCHIVED}
            handleActive={handleActiveTab}
            active={activeTab === TabEnum.ARCHIVED}
          />
          <Tab
            title={''}
            name={TabEnum.DOWNLOAD}
            handleActive={handleDownload}
            active={false}
          >
            <IconDownload />
            <If condition={downloading}>
              <Spinner
                style={{ margin: 0, height: 15, marginTop: -5, marginLeft: 10 }}
              />
            </If>
            <If condition={!downloading && csvData.length > 0}>
              <CSVDownload
                filename="user_list"
                separator={';'}
                data={csvData}
                target="_blank"
              />
            </If>
          </Tab>
        </Tabs>
        <UserNavActionContainer>
          <AddUserButton onClick={addNewUser}>
            <AddUserButtonLabel>{t('addUsersManually')}</AddUserButtonLabel>
            <IconPlusCircle width={20} fill="#004A7A" />
          </AddUserButton>
        </UserNavActionContainer>
      </UserNavContainer>
      <UserContentContainer>
        <If condition={!loading && activeTab === TabEnum.ALL}>
          <AllUsers content={AllUsersItems} />
        </If>
        <If condition={!loading && activeTab === TabEnum.PENDING}>
          <Pending content={pendingUsersItems} />
        </If>
        <If condition={!loading && activeTab === TabEnum.ARCHIVED}>
          <Archived content={archivedUsersItems} />
        </If>
        <If condition={loading}>
          <Loading style={{ height: '20vh' }} />
        </If>
      </UserContentContainer>
    </Container>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  resortId: state.auth.data.resortId
})

export default connect(mapStateToProps, null)(UsersPage)
