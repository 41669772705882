import { Slider } from '@mui/material'
import React from 'react'
import { If } from 'react-extras'
import styled from 'styled-components'
import theme from 'styles/theme'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Title = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
`

const StyledSlider = styled(Slider)`
  width: 97% !important;
  left: 1.5%;
  margin-bottom: -1.5%;

  .MuiSlider-rail,
  .MuiSlider-track {
    background-color: ${theme.colors.yellow};
    border: none;
    height: 1px;
  }
  .MuiSlider-thumb {
    background-color: ${theme.colors.yellow};
    height: 1rem;
    width: 1rem;
  }
`

const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const UnderlinedBoldText = styled.p`
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
`

const SmallText = styled.p`
  margin: 0;
  font-size: 0.6rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.lightGray};
`
interface SliderFilterProps {
  title: string
  value: number | number[]
  minVal: number
  maxVal: number
  onChange: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void
  onChangeCommitted?:
    | ((
        event: Event | React.SyntheticEvent<Element, Event>,
        value: number | number[]
      ) => void)
    | undefined
}

const SliderFilter = ({
  value,
  minVal,
  maxVal,
  onChange,
  onChangeCommitted,
  title
}: SliderFilterProps) => {
  const value1 = (value as number[])[0]
  const value2 = (value as number[])[1]

  const SliderProps = {
    onChange,
    onChangeCommitted,
    value,
    title
  }
  return (
    <Container>
      <TwoColumnContainer>
        <Title>{title}</Title>
        <If condition={value1 === value2}>
          <UnderlinedBoldText>{value1}</UnderlinedBoldText>
        </If>
        <If condition={Array.isArray(value) && value1 !== value2}>
          <UnderlinedBoldText>
            {(value as number[])[0]} - {(value as number[])[1]}
          </UnderlinedBoldText>
        </If>
      </TwoColumnContainer>
      <div>
        <StyledSlider valueLabelDisplay="auto" {...SliderProps} />
        <TwoColumnContainer>
          <SmallText>{minVal}</SmallText>
          <SmallText>{maxVal}</SmallText>
        </TwoColumnContainer>
      </div>
    </Container>
  )
}

export default SliderFilter
