import styled from 'styled-components'

const ChartContainer = styled.div`
  display: block;
  flex-direction: column;
  border-radius: 8px;
  border: solid 2px #0072bb;
  position: relative;
  width: 400px;
  min-width: 360px;
  height: 180px;
  position: relative;

  :not(:last-child) {
    margin-right: 40px;
  }
`

const ChartTitleContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
`
const ChartTitle = styled.div`
  color: #93aef1;
  font-size: 13px;
`

const ChartSubTitle = styled.div`
  font-size: 35px;
  color: #0072bb;
`

const ChartSlopeContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  padding: 0 10px;
  flex-wrap: wrap;
`

const Slope = styled.div<{ active?: boolean }>`
  color: #0072bb;
  font-size: 13px;
  margin-bottom: 5px;
  cursor: pointer;
  ${props =>
    props.active &&
    `
    font-weight: bold;
  `}
  :not(:last-child) {
    margin-right: 20px;
  }
`

export {
  ChartContainer,
  ChartSlopeContainer,
  ChartSubTitle,
  ChartTitle,
  ChartTitleContainer,
  Slope
}
