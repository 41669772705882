import { Box, Button } from '@mui/material'
import { Loading } from 'components'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styles/theme'

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

export const InputLabel = styled.label`
  input[type='text'] {
    width: 300px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 25px;
    margin-top: 5px;
    padding: 1rem;
  }
`

export const RadioInputLabel = styled.label<{ $disabled?: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 5px;
  padding: 1rem;
  transition: 0.3s;
  border: 1px solid ${theme.colors.black};

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  &.active {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-color: ${theme.colors.primary};
  }
  ${props =>
    props.$disabled && {
      pointerEvents: 'none',
      opacity: 0.5
    }}
`

export const AddSlopeButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.black};
  text-decoration: none;
`

export const AddSlopeButtonLabel = styled.span`
  font-size: 16px;
  margin-right: 10px;
`

export const EditButton = styled(Link)`
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  height: 40px;
  width: auto;

  & span {
    margin-right: 10px;
  }
  & svg {
    width: 30px;
    padding-right: 5px;
    padding-top: 5px;
  }
`

export const ImageWrapper = styled(Box)`
  width: 320px;
  height: 240px;
  background-color: #eeeeee;
  margin-bottom: 0;
  position: relative;
`

export const StyledLoading = styled(Loading)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
`

export const NoImageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const SmallImgPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

export const FullImgPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const SubmitButton = styled(Button)`
  color: white;
  height: 40px;
  background-color: ${theme.colors.black};
  box-shadow: none;
  text-transform: none;

  &:hover {
    box-shadow: none;
  }
`

export const PageTitle = styled.div`
  font-weight: bold;
  font-size: 37px;
  margin-bottom: 1.5rem;
  color: ${theme.colors.black};
`
