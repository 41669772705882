import signInImg from 'assets/images/sloa-guy-skiing.png'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${theme.colors.primary} 0px 10px 50px -20px;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
`

const SignInImage = styled.div`
  position: relative;
  background-image: url(${signInImg});
  background-size: cover;
  width: 100%;
  height: 240px;
  border-radius: 5px;
`

const SignInImageText = styled.div<{ color?: string }>`
  font-size: ${theme.textSizes.normal};
  color: ${({ color }) => (color ? color : '#fff')};
`

const SignInHeading = styled.h1`
  margin: 30px 0;
  font-size: ${theme.textSizes.large};
  align-self: flex-start;
  color: ${theme.colors.gray};
`

const SignInFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding: 20px;
`

const ErrorText = styled.span`
  display: block;
  color: ${theme.colors.red};
  text-align: center;
  margin: 15px;
`

const FullWidth = styled.div`
  width: 100%;
`

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  margin-bottom: 20px;
`

const LinkForgotPassword = styled.div`
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  margin-top: 13px;
  cursor: pointer;
`

export {
  CenterContainer,
  ErrorText,
  ForgotPasswordContainer,
  FullWidth,
  LinkForgotPassword,
  SignInContainer,
  SignInFormContainer,
  SignInHeading,
  SignInImage,
  SignInImageText
}
