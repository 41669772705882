import React from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { TextSizeType, VariantType } from '.'

const getStyleByVariant = (variant?: VariantType) => {
  const { primary, white, gray, blue, black } = theme.colors
  switch (variant) {
    case 'primary':
    default:
      return {
        background: primary,
        color: white,
        textAlign: 'left'
      }
    case 'secondary':
      return {
        background: white,
        color: primary,
        textAlign: 'left'
      }
    case 'outlined':
      return {
        background: white,
        color: gray,
        textAlign: 'left'
      }
    case 'light':
      return {
        background: blue,
        color: white,
        textAlign: 'center'
      }
    case 'default':
      return {
        background: 'transparent',
        color: black,
        textAlign: 'left',
        borderColor: black
      }
  }
}

interface IButtonContainerProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: VariantType
  textSize?: TextSizeType
}

const ButtonContainer = styled.button<IButtonContainerProps>`
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: ${({ variant }) => getStyleByVariant(variant).textAlign};
  border-radius: 8px;
  padding: 15px 25px;
  color: ${({ variant }) => getStyleByVariant(variant).color};
  font-size: ${({ textSize }) =>
    textSize === 'small' ? theme.textSizes.small : theme.textSizes.large};
  background-color: ${({ variant, disabled }) =>
    disabled ? theme.colors.lightGray : getStyleByVariant(variant).background};
  border: 1px solid
    ${({ variant, disabled }) =>
      getStyleByVariant(variant).borderColor ?? disabled
        ? theme.colors.lightGray
        : getStyleByVariant(variant).background};
  transition: 0.2s;
  min-width: 220px;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    fill: ${({ variant }) => getStyleByVariant(variant).color} !important;
    height: 20px;
    width: 20px;
    margin-left: 40px;
  }
`

export { ButtonContainer }
