import styled from 'styled-components'

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableRow = styled.tr`
  padding-bottom: 10px;
  height: 50px;
`

const TableHeaderCell = styled.th`
  font-size: 16px;
  color: #004a7a;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
`

const TableCell = styled.td<{ center?: boolean }>`
  font-size: 16px;
  color: #004a7a;
  text-align: left;
  border-bottom: 1px solid #d2d2d2;
  ${props =>
    props.center &&
    `
    text-align: right;
    padding-left: 10px;
  `}
`

const EditButton = styled.button`
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  & span {
    margin-right: 10px;
  }
  & svg {
    width: 30px;
    padding-right: 5px;
    padding-top: 5px;
  }
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.4;
  }
`

export { EditButton, TableCell, TableContainer, TableHeaderCell, TableRow }
