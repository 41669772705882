import styled from 'styled-components'

const ConsentPersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ConsentPersonalInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const ConsentPersonalInfoFooter = styled.div`
  display: flex;
  flex-direction: column;
`

const ConsentPersonalInfoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  padding: 0 10px;
  width: 40%;
`

const ConsentTextArea = styled.textarea`
  border: 1px solid #0072bb;
  border-radius: 4px;
  height: 45px;
  margin: 10px 0;
`

export {
  ConsentPersonalInfoButtonContainer,
  ConsentPersonalInfoContainer,
  ConsentPersonalInfoFooter,
  ConsentPersonalInfoTitle,
  ConsentTextArea
}
