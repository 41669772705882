import { IconArrowRight, IconEdit } from 'assets/images'
import { t } from 'i18n'
import { Fragment } from 'react'
import { If } from 'react-extras'
import {
  EditButton,
  TableCell,
  TableContainer,
  TableHeaderCell,
  TableRow
} from './styled'

interface ITableProps {
  header?: string
  columns: string[]
  items?: TableItem[]
  keyIndex: number
  showIndex?: boolean
  onEdit?: (id?: string | number, readOnly?: boolean) => void
}

const UserTable = ({ columns, items, onEdit }: ITableProps) => {
  return (
    <TableContainer>
      <thead>
        <TableRow>
          {columns &&
            columns.map((column: string) => (
              <TableHeaderCell key={`#${column}`}>
                {column && t(column)}
              </TableHeaderCell>
            ))}
        </TableRow>
      </thead>
      <tbody>
        {items &&
          items.map((item: TableItem) => (
            <TableRow key={item.id}>
              {item.values &&
                item.values.map((value: string) => (
                  <Fragment key={`#${value}`}>
                    <If
                      condition={
                        value !== 'edit' &&
                        value !== 'approve' &&
                        value !== 'openUser'
                          ? true
                          : false
                      }
                    >
                      <TableCell key={`#${value}`}>{value}</TableCell>
                    </If>
                    <If condition={value === 'edit' ? true : false}>
                      <TableCell align="center" center key={`#${value}`}>
                        <EditButton
                          disabled={item.readonly}
                          onClick={() => onEdit && onEdit(item.id)}
                        >
                          <IconEdit />
                        </EditButton>
                      </TableCell>
                    </If>
                    <If condition={value === 'approve' ? true : false}>
                      <TableCell align="center" center key={`#${value}`}>
                        <EditButton
                          disabled={item.readonly}
                          onClick={() => onEdit && onEdit(item.id)}
                        >
                          <span> {t('Approve')}</span>
                          <IconArrowRight fill="#0072BB" width={20} />
                        </EditButton>
                      </TableCell>
                    </If>
                    <If condition={value === 'openUser' ? true : false}>
                      <TableCell align="center" center key={`#${value}`}>
                        <EditButton
                          disabled={item.readonly}
                          onClick={() => onEdit && onEdit(item.id)}
                        >
                          <span> {t('OpenUser')}</span>
                          <IconArrowRight fill="#0072BB" width={20} />
                        </EditButton>
                      </TableCell>
                    </If>
                  </Fragment>
                ))}
            </TableRow>
          ))}
      </tbody>
    </TableContainer>
  )
}

export { UserTable }
