import styled from 'styled-components'

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 2rem;
`
const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
`
const Value = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
`

interface SimpleStatisticProps {
  label: string
  value: string | number
}

const SimpleStatistic = ({ label, value }: SimpleStatisticProps) => {
  return (
    <Container>
      <Title>{label}</Title>
      <Value>{value}</Value>
    </Container>
  )
}

export default SimpleStatistic
